// id       自增id
// material_type  素材类型  1视频，图片
// material_uid  素材UID
// tagType     分类类型
// tagType_id   分类id
// material_name 素材名称
// material_desc  素材描述
// group       分组id
// group_desc   分组描述
// video_url   视频url
// poster_url  封面
// pic_url   图片url
// belongto_id  归属id
// belongto_user  归属人
// create_time  创建日期
// create_by  创建人
// update_time   更新日期
// update_by  更新人
// upload_time  上传日期
// upload_by  上传人
// permission 权限 0私有，1公共
// filezise  文件大小
const columns = [{
        title: "项目名称",
        dataIndex: "projectName",
        sorter: true,
        width: "200px",
        key: "projectName",
    },
    {
        title: "创建人",
        dataIndex: "belongto_user",
        key: "belongto_user",
        // filters: [
        //   {
        //     text: "Male",
        //     value: "male",
        //   },
        //   {
        //     text: "Female",
        //     value: "female",
        //   },
        // ],
        width: "200px",
    },

    {
        title: "创建日期",
        dataIndex: "create_time",
        key: "create_time",
        width: "200px",
        sorter: true,
    },
    {
        title: "更新日期",
        dataIndex: "update_time",
        sorter: true,
        key: "update_time",
        width: "200px",
    },
    {
        title: "查看权限",
        dataIndex: "permission",
        key: "permission",
        width: "100px",
    },
    {
        title: "操作",
        dataIndex: "action",
        key: "action",
        width: "180px",
        fixed: "right",
        textAlign: "center",
    },
];
const columnsList = [{
        title: "素材名称",
        dataIndex: "material_name",

        width: "200px",
        key: "material_name",
        align: 'center',
        sorter: {
            compare: (a, b) => a.material_name - b.material_name,
            multiple: 3,
        },
    },
    {
        title: "封面",
        dataIndex: "poster_url",
        key: "poster_url",
        // filters: [
        //   {
        //     text: "Male",
        //     value: "male",
        //   },
        //   {
        //     text: "Female",
        //     value: "female",
        //   },
        // ],
        width: "200px",
        align: 'center'
    },
    {
        title: "素材类型",
        dataIndex: "material_type",
        key: "material_type",
        // filters: [
        //   {
        //     text: "Male",
        //     value: "male",
        //   },
        //   {
        //     text: "Female",
        //     value: "female",
        //   },
        // ],
        width: "200px",
        align: 'center'
    },
    {
        title: "素材尺寸",
        dataIndex: "material_desc",
        key: "material_desc",
        // filters: [
        //   {
        //     text: "Male",
        //     value: "male",
        //   },
        //   {
        //     text: "Female",
        //     value: "female",
        //   },
        // ],
        width: "200px",
        align: 'center'
    },
    {
        title: "分类",
        dataIndex: "tagType",
        key: "tagType",
        width: "100px",
        align: 'center'
    },
    {
        title: "文件类型",
        dataIndex: "extension",
        key: "extension",
        width: "100px",
        align: 'center'
    },
    {
        title: "上传人",
        dataIndex: "upload_by",
        key: "upload_by",
        width: "100px",
        sorter: {
            compare: (a, b) => a.upload_by - b.upload_by,
            multiple: 3,
        },
        align: 'center'
    },

    {
        title: "上传日期",
        dataIndex: "upload_time",
        key: "upload_time",
        width: "200px",
        sorter: {
            compare: (a, b) => a.upload_time - b.upload_time,
            multiple: 3,
        },
        align: 'center'
    },
    {
        title: "更新日期",
        dataIndex: "update_time",
        sorter: true,
        key: "update_time",
        width: "200px",
        sorter: {
            compare: (a, b) => a.update_time - b.update_time,
            multiple: 3,
        },
        align: 'center'
    },
    {
        title: "查看权限",
        dataIndex: "permission",
        key: "permission",
        width: "200px",
        align: 'center'
    },
    {
        title: "操作",
        dataIndex: "action",
        key: "action",
        width: "180px",
        fixed: "right",
        align: 'center'
    },
];

export {
    columns,
    columnsList
}