<template>
  <div>
    <div class="warp-card">
      <div class="warp-card-body">
        <a-table :columns="columns" :data-source="dataSource" :scroll="{ x: 1500 }" :loading="loading">
          <template #bodyCell="{ column, text, record }">
            <slot name="tableBody" :column="column" :text="text" :record="record"></slot>
          </template>
        </a-table>
      </div>
    </div>
  </div>
</template>
<script>
import { computed, defineComponent, reactive, toRefs } from "vue";

export default defineComponent({
  props: {
    dataSource: {
      default: () => {
        return [];
      },
      type: Array,
    },
    loading: {
      default: () => {
        return false;
      },
      type: Boolean,
    },
    columns: Array,
  },
  setup(props, context) {
    var { dataSource, columns } = toRefs(props);
    // const data = reactive({
    //   dataSource: [
    //     {
    //       name: "xxx视频",
    //       video:
    //         "http://preview.qiantucdn.com/meijing/30/96/18/47N58PICIA2H4jhCcAu6S_PIC2018.jpg!kuan600",
    //       upby: "小芳",
    //       upby4: "2020-12-11",
    //       upby2: "2020-12-11",
    //       upby1: "2020-12-11",
    //       public: "公共",
    //       tags: ["广告视频", "抖音推广"],
    //     },
    //     {
    //       name: "xxx视频",
    //       video:
    //         "http://preview.qiantucdn.com/meijing/30/96/18/47N58PICIA2H4jhCcAu6S_PIC2018.jpg!kuan600",
    //       upby: "小明",
    //       upby4: "2020-12-11",
    //       upby2: "2020-12-11",
    //       upby1: "2020-12-11",
    //       public: "公共",
    //       tags: ["公司内部", "生日派对"],
    //     },
    //   ],
    //   columns,
    // });
    return {
      ...toRefs(props),
    };
  },
});
</script>
<style lang="less" scoped>
.warp-card {
  background: #fff;
  box-shadow: 0 2px 7px 0 rgba(5, 34, 97, 0.1);
  border-radius: 4px;
  box-sizing: border-box;
}

.warp-card-body {
  // padding: 32px 40px 40px;
  padding-top: 32px;
}
</style>