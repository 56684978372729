<template>
  <customModel ref="myModal" title="编辑项目" @handleOk="handdleOk" @handleCancel="handleCancel" :modalVisible="visible">
    <a-form ref="formRef" name="custom-validation" :model="formState" :rules="rules" v-bind="layout"
      @finish="handleFinish" @validate="handleValidate" @finishFailed="handleFinishFailed">
      <a-form-item label="项目名称" name="projectName">
        <a-input v-model:value="formState.projectName" type="text" autocomplete="off" />
      </a-form-item>
      <a-form-item label="权限" name="permission">
        <a-select v-model:value="formState.permission" placeholder="请选择">
          <a-select-option :value="0">私有</a-select-option>
        </a-select>
      </a-form-item>

      <a-form-item :wrapper-col="{ span: 14, offset: 4 }">
        <a-button type="primary" html-type="submit">提交</a-button>
        <a-button style="margin-left: 10px" @click="resetForm">重置</a-button>
      </a-form-item>
      <!-- <a-form-item has-feedback label="上传图" name="materialDesc">
            <upFile @uploadDone="handleUploadDone" />
          </a-form-item> -->
    </a-form>
  </customModel>
</template>
<script >
import customModel from "@/components/modelComponent/customModel.vue";
import { updateProject } from "@/api/project";
import {
  defineComponent,
  getCurrentInstance,
  ref,
  defineExpose,
  watch,
  toRefs,
  reactive,
} from "vue";

export default defineComponent({
  components: {
    customModel,
  },
  setup(props, { emit }) {
    // 获取实例
    const { proxy } = getCurrentInstance();
    //ref
    const myModal = ref(null);
    const formRef = ref(null);
    //state
    const stateData = reactive({ visible: false });
    const formState = reactive({
      projectName: "",
      permission: 0,
      id: null,
    });

    const layout = {
      labelCol: {
        span: 4,
      },
      wrapperCol: {
        span: 14,
      },
    };
    const rules = reactive({
      projectName: [
        { required: true, message: "请输入项目名称", trigger: "blur" },
      ],
      permission: [{ required: false }],
    });
    const visible = ref(false);

    const handleOpenModel = (value, record) => {
      visible.value = value;
      formState.projectName = record.projectName;
      formState.permission = record.permission;
      formState.id = record.id;
    };
    const openModelBymethod = (value) => {
      myModal.value.openModal({ visible: value, title: "添加项目" });
    };
    const handleCancel = (value) => {
      visible.value = value;
      resetForm();
    };
    const handdleOk = () => { };
    /**
     * 添加站点
     */
    const handleFinish = (values) => {
      // console.log(values, formState);
      updateProject(formState)
        .then((res) => {
          if (res.code == "ok") {
            proxy.$Message.success(res.msg);
            emit("handleGetList");
          } else {
            proxy.$Message.error(res.msg);
          }
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          handleCancel(false);
        });
    };
    const handleFinishFailed = (errors) => {
      console.log(errors);
    };
    const resetForm = () => {
      formRef.value.resetFields();
    };
    const handleValidate = (...args) => {
      console.log(args);
    };
    return {
      //   ...toRefs(stateData),
      //state

      visible,
      formState,
      layout,
      rules,
      //ref
      formRef,
      myModal,
      //methods
      handdleOk,
      openModelBymethod,
      handleOpenModel,
      handleCancel,

      handleFinish,
      handleFinishFailed,
      resetForm,
      handleValidate,
    };
  },
});
</script>
