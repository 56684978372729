<template>
  <div class="home">
    <review ref="refreview"></review>
    <div class="tab-type">
      <div style="padding: 10px">
        <a-breadcrumb :style="{ margin: '16px 0' }">
          <a-breadcrumb-item
            ><a style="color: #1890ff" href="/home">主页</a></a-breadcrumb-item
          >

          <a-breadcrumb-item
            ><a style="color: #1890ff" href="/project"
              >项目</a
            ></a-breadcrumb-item
          >
          <a-breadcrumb-item><a>素材列表</a></a-breadcrumb-item>
        </a-breadcrumb>
      </div>
      <a-card>
        <a-tabs type="card">
          <a-tab-pane key="1" tab="素材列表">
            <div class="card-row">
              <div class="search-box">
                <div class="table-top-layout"></div>
              </div>
              <div class="up-box"></div>
            </div>
            <tableComponent
              :columns="columns"
              :dataSource="dataSource"
              :loading="stateData.loading"
            >
              <template v-slot:tableBody="{ column, text, record }">
                <template v-if="column.dataIndex === 'action'">
                  <span>
                    <!-- <a-popconfirm
                      title="确定删除?"
                      ok-text="是"
                      cancel-text="否"
                      @confirm="del(record)"
                    >
                      <a-button size="small">删除</a-button>
                    </a-popconfirm>

                    <a-button
                      @click="handleViewImg(record.poster_url)"
                      size="small"
                      >预览</a-button
                    > -->

                    <div style="padding: 5px">
                      <a-button
                        size="small"
                        @click="openReViewModel(record)"
                        type="primary"
                        >预览</a-button
                      >
                      <a-divider type="vertical" />
                      <a-button
                        size="small"
                        @click="downMaterial(record)"
                        type="primary"
                        >下载</a-button
                      >
                    </div>

                    <div style="padding: 5px">
                      <!-- <a-popconfirm
                        title="确定删除?"
                        ok-text="是"
                        cancel-text="否"
                        @confirm="del(record)"
                      >
                        <a-button size="small">删除</a-button>
                      </a-popconfirm> -->
                      <a-dropdown :trigger="['click']">
                        <a class="ant-dropdown-link" @click.prevent>
                          更多
                          <DownOutlined />
                        </a>
                        <template #overlay>
                          <a-menu>
                            <!-- <a-menu-item key="0">
                              <a href="http://www.alipay.com/">编 辑</a>
                            </a-menu-item> -->
                            <a-menu-item key="1">
                              <a @click="del(record)">删 除</a>
                            </a-menu-item>
                          </a-menu>
                        </template>
                      </a-dropdown>
                    </div>
                  </span>
                </template>
                <template v-else-if="column.dataIndex === 'tagType'">
                  <span>
                    <a-tag :color="'green'">
                      {{ record.tagType }}
                    </a-tag>
                  </span>
                </template>

                <template v-else-if="column.dataIndex === 'poster_url'">
                  <span>
                    <img
                      :src="text"
                      style="max-width: 200px; max-height: 60px"
                      alt=""
                    />
                  </span>
                </template>
                <!-- <template v-else-if="column.dataIndex === 'permission'">
                  <span v-if="text == 1"> 公共 </span>
                </template> -->
                <template v-else-if="column.dataIndex === 'material_type'">
                  <span v-if="text == 1"> 视频 </span>
                  <span v-else-if="text == 2"> 图片 </span>
                </template>
                <template v-else-if="column.dataIndex === 'permission'">
                  <span> 公共 </span>
                  <!-- <span v-if="text == 1"> 公共 </span> -->
                </template>
              </template>
            </tableComponent>
          </a-tab-pane>
        </a-tabs>
      </a-card>
    </div>
    <addModel ref="refaddModel" @handleGetList="getItemList"></addModel>
    <editModel ref="refEditModel" @handleGetList="getItemList"></editModel>
    <a-modal
      v-model:visible="visible"
      :footer="null"
      width="1000px"
      title="预览"
      @ok="handleOk"
    >
      <p><img :src="viewImg" alt="" /></p>
    </a-modal>
  </div>
</template>

<script>
// @ is an alias to /src

import tableComponent from "@/components/table/tableComponent.vue";
import { defineComponent, reactive, ref, toRaw, getCurrentInstance } from "vue";
import { useRoute } from "vue-router";

import { useStore } from "vuex";

import {
  PoweroffOutlined,
  CloudUploadOutlined,
  DownOutlined,
} from "@ant-design/icons-vue";

import { columnsList } from "./columns.js";

import { getProjectMaterialList, delProjectMaterial } from "@/api/project";
import { load } from "@/utils/loading/load.js";
import addModel from "./modal/addProject.vue";
import editModel from "./modal/editProject.vue";
import review from "../index/component/modal/review";
export default defineComponent({
  name: "Home",
  components: {
    tableComponent,
    PoweroffOutlined,
    CloudUploadOutlined,
    addModel,
    editModel,
    review,
    DownOutlined,
  },

  setup(props, { emit }) {
    const stateData = reactive({
      loading: false,
    });
    const dataSource = ref([]);
    const refaddModel = ref(null);
    const refEditModel = ref(null);
    const { proxy } = getCurrentInstance();
    /**
     * 获取项目列表
     */ const refreview = ref(null);
    const visible = ref(false);
    const viewImg = ref("");
    const route = useRoute();
    const handleProjectList = async () => {
      // load.show("加载中");
      stateData.loading = true;
      let res = await getProjectMaterialList({ id: route.query.id });
      stateData.loading = false;
      dataSource.value = res.data;
      // setTimeout(() => {
      //   load.hide();
      // }, 2000);
    };
    const openModel = () => {
      refaddModel.value.handleOpenModel(true);
    };
    const edit = (record) => {
      refEditModel.value.handleOpenModel(true, record);
    };
    const del = (record) => {
      delProjectMaterial({ id: record.uid }).then((res) => {
        if (res.code == "ok") {
          proxy.$Message.success(res.msg);
          handleProjectList();
        } else {
          proxy.$Message.error(res.msg);
        }
      });
    };
    const handleCreate = () => {
      handleProjectList();
    };
    handleCreate();
    const getItemList = () => {
      handleProjectList();
    };
    const handleViewImg = (str) => {
      visible.value = true;
      viewImg.value = str;
    };
    const handleOk = () => {
      visible.value = false;
    };
    const downFileUrl = (img, material_name) => {
      //var img = "后台返回的文件地址"; //注意需要拼接域名访问哦~
      const url = img;
      let xmlhttp = new XMLHttpRequest();
      xmlhttp.open("GET", url, true);
      xmlhttp.responseType = "blob";
      xmlhttp.onload = function () {
        if (this.status == 200) {
          const blob = this.response;
          const link = document.createElement("a");
          link.href = window.URL.createObjectURL(blob);
          link.download =
            material_name + url.substring(url.lastIndexOf("."), url.length);
          //此写法兼容可火狐浏览器
          document.body.appendChild(link);
          const evt = document.createEvent("MouseEvents");
          evt.initEvent("click", false, false);
          link.dispatchEvent(evt);
          window.URL.revokeObjectURL(link.href);
          document.body.removeChild(link);
        }
      };
      xmlhttp.send();
    };
    const download = (url, name) => {
      const a = document.createElement("a");
      a.href = url;
      a.download = name;
      a.target = "_blank";
      a.click();
      a.remove();
    };
    const downMaterial = (record) => {
      if (record.material_type == 1) {
        download(record.video_url, record.material_name);
      } else {
        downFileUrl(record.pic_url, record.material_name);
      }
    };
    const openReViewModel = (item) => {
      // console.log(item.material_type);
      // reviewVisible.value = true;
      // sourceUrl.value = item.video_url;
      // reviewType.value = item.material_type;
      console.log(item);
      refreview.value.handleOpenModel(true, item);
    };
    return {
      //ref
      refaddModel,
      refEditModel,
      //data
      stateData,
      viewImg,
      visible,
      columns: columnsList,
      dataSource,
      //method
      openModel,
      getItemList,
      edit,
      del,
      handleViewImg,
      handleOk,
      downMaterial,
      refreview,
      openReViewModel,
    };
  },
});
</script>
<style scoped lang="less">
.card-row {
  padding: 10px;

  .search-box {
    display: inline-block;
  }

  .up-box {
    display: inline-block;
    float: right;
  }
}

.tab-type {
}
</style>